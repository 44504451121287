



























































import { DeletePopUp } from './deletePopUp';
export default DeletePopUp;
