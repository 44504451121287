import tableStore from '@/store/modules/table';
import { Vue, Component } from 'vue-property-decorator';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import programListStore from '@/store/modules/programList';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ActionsDropdown from '@/commoncomponents/actionsdropdowncomponent/ActionsDropdown.vue';
import UIkit from 'uikit';
import DeleteUsers from '@/popupcomponents/deletePopUp/deletePopUp.vue';
import { IUserRoles, SearchObj, SortObjc } from '@/Model/model';

@Component({
  components: {
    'sites-table': SmartTableContainer,
    'bouncing-preloader': BouncingPreloaderComponent,
    'actions-dropdown': ActionsDropdown,
    'delete-users': DeleteUsers,
  }
})
export default class Sites extends Vue {
    public tableHeaders = APP_CONST.SITES_TABLE_HEADER;
    public actionsDropdown: Array<any> = APP_CONST.SITES_ACTIONS_DROPDOWN;
    public sitesListResponse: boolean = false;
    public roleId: number = APP_CONST.MINUS_ONE;
    public isAccountAdmin: boolean = false;
    public highest_role: number = APP_CONST.MINUS_ONE;
    public searchLists: any = <any>[{ header: 'autoEnrollment', values: ['On', 'Off'] }];
    public displayColumn: any = [];
    public siteIds: number = 0;
    public apiResponseCode: number = 0;
    public isValidate: boolean = true;
    public sitesList: any = [];
    public displayAll: boolean = true;
    public initialTotalItems: number = 0;
    public localConst: any = APP_CONST;
    public weekValues:any = [
      {'Su': 0},
      {'M': 1},
      {'T': 2},
      {'W': 3},
      {'Th': 4},
      {'F': 5},
      {'S': 6}
    ];

    /* istanbul ignore next */
    get filteredActionsDropdown() {
      if (!(this.roleId > APP_CONST.ZERO && this.roleId < APP_CONST.ACCOUNT_ADMIN_ROLE_ID)) {
        APP_UTILITIES.findAndSplice(this.actionsDropdown, 'name', 'Delete');
      }
      return this.actionsDropdown;
    }

    /* istanbul ignore next */
    get sitesHeaders() {
      return programListStore.programsHeaders;
    }

    /* istanbul ignore next */
    get sitesData() {
      return programListStore.sitesList;
    }

    /* istanbul ignore next */
    get roleInProgram() {
      const roles: any = JSON.parse(APP_UTILITIES.getCookie('all_programs_associated_to_program_admin') || '');
      let matchedRole: any = {};
      roles && roles.forEach((role: IUserRoles) => {
        if (role.programId == Number(APP_UTILITIES.getCookie('program_id'))) {
          matchedRole = role;
        }
      });
      return matchedRole && matchedRole.roleId
        ? matchedRole.roleId
        : APP_CONST.PROGRAM_ADMIN_ROLE_ID;
    }

    /* istanbul ignore next */
    sitesListData() {
      this.sitesData && this.sitesData && this.sitesData.sitesList.forEach((site: any) => {
        site.time = site.isTimeAdded
          ? `${APP_UTILITIES.convert12hrFormateTimeFromDate(site.startDate).toUpperCase()} - ${APP_UTILITIES.convert12hrFormateTimeFromDate(site.endDate).toUpperCase()}`
          : '';
        site.startDate = APP_UTILITIES.formatShortDate(site.startDate);
        site.endDate = APP_UTILITIES.formatShortDate(site.endDate);
        this.sortWeekDaysFnc(site);
        if (!site.hasOwnProperty('state')) {
          site.state = '';
        }
        site.siteAddress = `${site.siteAddress}${site.siteAddress.length
          ? ', '
          : ''}${site.siteAddress2}${site.siteAddress2.length
          ? ', '
          : ''}${site.city}${site.city.length
          ? ', '
          : ''}${site.state}${site.state.length
          ? ', '
          : ''}${site.postalCode}`;
        site.waitListCapacity = site.waitListCapacity
          ? site.waitListCapacity
          : site.waitListCapacity == 0
            ? '0'
            : '';
        site.selected = false;
      });
      this.sitesData && this.sitesData && this.sitesData.sitesList.forEach((site: any) => {
        tableStore.allDataSelected && tableStore.allDataSelected.length && tableStore.allDataSelected.forEach((elementChecked: any) => {
          if (elementChecked.siteId === site.siteId && site.siteName === elementChecked.siteName) {
            site.selected = elementChecked.selected;
          }
        });
      });
      if (tableStore.allSelect) {
        this.sitesData && this.sitesData && this.sitesData.sitesList.forEach((site: any) => {
          site.selected = true;
        });
        this.sitesData && this.sitesData && this.sitesData.sitesList.forEach((site: any) => {
          tableStore.unSelectData && tableStore.unSelectData.length && tableStore.unSelectData.forEach((elementChecked: any) => {
            if (elementChecked.siteId === site.siteId && site.siteName === elementChecked.siteName) {
              site.selected = false;
            }
          });
        });
      }
      this.sitesList = this.sitesData.sitesList;
    }

    sortWeekDaysFnc(site:any){
      const selectedDaysArr= site.selectedDays.split(',',site.selectedDays.length);
      this.weekValues.forEach((el:any) => {
        selectedDaysArr.forEach((elem:any)=>{
          if(elem == Object.keys(el)){
            const index = selectedDaysArr.indexOf(elem);
            if (index !== -1) {
              selectedDaysArr[index] = Object.values(el);
            }
          }
        });
      });
      let weekDaysSelectedArr = selectedDaysArr.join();
      weekDaysSelectedArr = JSON.parse('[' + weekDaysSelectedArr + ']');
      weekDaysSelectedArr.sort(function(a:any, b:any){
        if (a < b){
          return -1;
        }
        if (a > b){
          return 1;
        }
        return 0;
      });
      let sortedDays:any = [];
      weekDaysSelectedArr.forEach((index:any)=>{
        switch(index){
          case 0:
            sortedDays.push('Su');
            break;
          case 1:
            sortedDays.push('M');
            break;
          case 2:
            sortedDays.push('T');
            break;
          case 3:
            sortedDays.push('W');
            break;
          case 4:
            sortedDays.push('Th');
            break;
          case 5:
            sortedDays.push('F');
            break;
          case 6:
            sortedDays.push('S');
            break;
        }
      });
      sortedDays = sortedDays.toString();
      site.selectedDays = sortedDays;
      site.selectedDays = site.selectedDays.replace(/,/g, ', ');
    }

    /* istanbul ignore next */
    get sitesPayload() {
      return programListStore.currentSitesPayload;
    }

    /* istanbul ignore next */
    get siteResizesData() {
      return programListStore.siteResizesData;
    }

    /* istanbul ignore next */
    get allSiteDataSelected() {
      return tableStore.allDataSelected;
    }

    /* istanbul ignore next */
    get siteSearchObj() {
      return programListStore.siteSearchObj;
    }

    /* istanbul ignore next */
    get isLoaderVisible() {
      return (this.initialTotalItems >= this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO);
    }

    /* istanbul ignore next */
    async mounted() {
      this.getSiteList().then(() => {
        this.initialTotalItems = this.sitesData.count;
      });
      const { roleId } = APP_UTILITIES.coreids();
      this.roleId = roleId;
      this.isAccountAdmin = Boolean(APP_UTILITIES.getCookie('isAccountAdmin'));
      this.highest_role = Number(APP_UTILITIES.getCookie('highest_role'));
      this.displayColumn = programListStore.sitesDisplayColumns;
    }

    /* istanbul ignore next */
    created() {
      programListStore.mutateSiteFilterObject(JSON.parse(JSON.stringify(APP_CONST.SITES_TABLE_HEADER.MOVABLE)));

      programListStore.mutateSiteCurrentObj({ ...this.siteSearchObj, siteName: '', noOfScholars: '', scholarCapacity: '', ageRestriction: '', autoEnrollment: '', waitListCapacity: '', isExactMatch: false });
    }

    async getSiteList() {
      programListStore.mutateSitesPayload({ ...this.sitesPayload });
      const programId = Number(APP_UTILITIES.getCookie('program_id'));
      if (programId) {
        await programListStore.getSitesByProgramId().then(() => {
          this.sitesListResponse = true;
          this.sitesListData();
        });
      }
    }

    clearSelection() {
      tableStore.mutateSelectAll(false);
      tableStore.mutateFewCheckData('');
      tableStore.mutateAllSelectedData([]);
      tableStore.mutateUnSelectedData([]);
      const element = <HTMLInputElement>document.getElementById('all-data-checkbox');
      if (element) {
        element.checked = false;
      }
      this.sitesList && this.sitesList.length && this.sitesList.forEach((element: any) => {
        element.selected = false;
      });
    }

    performAction(key: string) {
      switch (key) {
        case 'Add':
          this.addSite();
          break;
        case 'Delete':
          this.deleteSitePopUp();
          break;
        default:
          break;
      }
    }

    deleteSitePopUp() {
      let unSelectedIds: any = [];
      let selectedIds: any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((site: any) => {
            return site.siteId;
          });
        }
        if (unSelectedIds && unSelectedIds.length) {
          this.siteIds = this.sitesData.count - unSelectedIds.length;
        }
        else {
          this.siteIds = this.sitesData.count;
        }
      }
      else {
        selectedIds = this.allSiteDataSelected.map((site: any) => {
          return site.siteId;
        });
        this.siteIds = selectedIds.length;
      }
      this.apiResponseCode = APP_CONST.ZERO;
      this.isValidate = true;
      if (UIkit.modal('#delete-popup-confirmation') && this.siteIds > 0) {
        UIkit.modal('#delete-popup-confirmation').show();
      }
    }

    async deleteSites() {
      const programId = Number(APP_UTILITIES.getCookie('program_id'));
      const payload: any = {
        page: null,
        count: null,
        id: programId,
        active: 0,
        sortField: this.sitesPayload.sortField,
        sortDir: this.sitesPayload.sortDir,
        searchField: this.sitesPayload.searchField,
        search: this.sitesPayload.search,
        selectedIds: [],
        notSelectedIds: [],
        isAllSelected: tableStore.selectAll,
        isExactMatch: this.sitesPayload.isExactMatch
      };
      let unSelectedIds: any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((site: any) => {
            return site.siteId;
          });
        }
      }
      payload.notSelectedIds = unSelectedIds;
      const programIds: any = this.allSiteDataSelected.map((site: any) => {
        return site.siteId;
      });
      payload.selectedIds = programIds;
      this.isValidate = false;
      await programListStore.deleteBulkSite(payload).then((response: any) => {
        if (response.status == APP_CONST.RESPONSE_200) {
          this.apiResponseCode = APP_CONST.RESPONSE_200;
          if (UIkit.modal('#delete-popup-confirmation')) {
            UIkit.modal('#delete-popup-confirmation').$destroy(true);
          }
          programListStore.getProgramDetailsById(programId);
          programListStore.mutateSitesPayload({ ...this.sitesPayload, page: APP_CONST.ONE, search: decodeURIComponent(this.sitesPayload.search) });
          this.sitesListResponse = false;
          this.clearSelection();
          this.getSiteList();
        }
        else if (response.status == APP_CONST.RESPONSE_400) {
          this.apiResponseCode = APP_CONST.RESPONSE_400;
          this.isValidate = true;
        }
      });
    }

    addSite() {
      this.$router && this.$router.push('/account/program/sites/createsite');
    }

    getDataForPage(page: number) {
      programListStore.mutateSitesPayload({ ...this.sitesPayload, page: page });
      this.getSiteList();
    }

    sortData(sortObj: { sortField: string; sortDirection: number }) {
      programListStore.mutateSiteSortPayload({ ...this.sitesPayload, sortField: sortObj.sortField, sortDir: sortObj.sortDirection, page: APP_CONST.ONE });
      programListStore.mutateSitesPayload({ ...this.sitesPayload, sortField: sortObj.sortField, sortDir: sortObj.sortDirection, page: APP_CONST.ONE });
      this.sitesListResponse = this.isLoaderVisible
        ? false
        : this.sitesListResponse;
      this.clearSelection();
      this.getSiteList();
    }

    searchData(search: { searchField: string; isExactMatch: boolean }) {
      if (search.searchField) {
        programListStore.mutateSitesSearchObj({ ...this.siteSearchObj, [search.searchField]: (this.siteSearchObj && this.siteSearchObj[search.searchField]
          ? this.siteSearchObj[search.searchField]
          : ''), isExactMatch: search.isExactMatch });
      }
      else {
        programListStore.mutateSitesSearchObj({ ...this.siteSearchObj, programName: '', programType: '', noOfScholar: '', enrolledScholars: '', programStatus: '', noOfSites: '', isExactMatch: false });
      }
      programListStore.mutateSitesPayload({ ...this.sitesPayload, searchField: search.searchField, search: (this.siteSearchObj && this.siteSearchObj[search.searchField]
        ? this.siteSearchObj[search.searchField]
        : ''), page: APP_CONST.ONE, isExactMatch: search.isExactMatch });
      this.sitesListResponse = this.isLoaderVisible
        ? false
        : this.sitesListResponse;
      this.clearSelection();
      this.getSiteList();
    }

    filterColumns(displayColumn: any) {
      let isSelected = false;
      displayColumn && displayColumn.length && displayColumn.forEach((column: any) => {
        if (column.display) {
          isSelected = true;
        }
      });
      this.displayAll = isSelected;
      this.clearAllColumns(this.displayAll);
      programListStore.mutateSiteFilterObject(displayColumn);
      programListStore.mutateSiteSettingsData(displayColumn);
      programListStore.mutateSitesPayload({ ...this.sitesPayload });
    }

    clearAllColumns(displayAll: boolean) {
      let searchObj: SearchObj = {
        'searchField': this.sitesPayload && this.sitesPayload.searchField
          ? this.sitesPayload.searchField
          : '',
        'search': this.sitesPayload && this.sitesPayload.search
          ? this.sitesPayload.search
          : '',
      };
      let sortObjc: SortObjc = {
        'sortField': this.sitesPayload && this.sitesPayload.sortField
          ? this.sitesPayload.sortField
          : '',
        'sortDir': this.sitesPayload && this.sitesPayload.sortDir
          ? this.sitesPayload.sortDir
          : 0
      };
      const isSearchFieldFixed = this.tableHeaders.FIXED.map((e: any) => {
        return e.value; 
      }).indexOf(searchObj.searchField);
      const isSortFieldFixed = this.tableHeaders.FIXED.map((e: any) => {
        return e.value; 
      }).indexOf(sortObjc.sortField);

      if (!displayAll && isSearchFieldFixed == APP_CONST.MINUS_ONE) {
        searchObj = {
          'searchField': this.sitesPayload && this.sitesPayload.searchField == APP_CONST.SITE_NAME
            ? this.sitesPayload.searchField
            : '',
          'search': this.sitesPayload && this.sitesPayload.searchField == APP_CONST.SITE_NAME
            ? this.sitesPayload.search
            : '',
        };

        programListStore.mutateSitesSearchObj({ ...this.siteSearchObj, noOfScholars: '', scholarCapacity: '', ageRestriction: '', autoEnrollment: '', waitListCapacity: '', isExactMatch: false });
        programListStore.mutateSitesPayload({ ...this.sitesPayload, searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir, isExactMatch: searchObj.search
          ? this.sitesPayload.isExactMatch
          : false });
        this.getSiteList();
      }
      if (!displayAll && isSortFieldFixed == APP_CONST.MINUS_ONE) {
        sortObjc = {
          'sortField': this.sitesPayload && this.sitesPayload.sortField == APP_CONST.SITE_NAME
            ? this.sitesPayload.sortField
            : '',
          'sortDir': this.sitesPayload && this.sitesPayload.sortField == APP_CONST.SITE_NAME
            ? this.sitesPayload.sortDir
            : APP_CONST.ZERO
        };
        programListStore.mutateSiteSortPayload({ ...this.sitesPayload, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir });
        programListStore.mutateSitesSearchObj({ ...this.siteSearchObj, noOfScholars: '', scholarCapacity: '', ageRestriction: '', autoEnrollment: '', waitListCapacity: '', isExactMatch: false });
        programListStore.mutateSitesPayload({ ...this.sitesPayload, searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir, isExactMatch: searchObj.search
          ? this.sitesPayload.isExactMatch
          : false });
        this.getSiteList();
      }
    }

    filterDisplayColumn(column: any) {
      let searchObj: SearchObj = {
        'searchField': this.sitesPayload && this.sitesPayload.searchField
          ? this.sitesPayload.searchField
          : '',
        'search': this.sitesPayload && this.sitesPayload.search
          ? this.sitesPayload.search
          : '',
      };
      let sortObjc: SortObjc = {
        'sortField': this.sitesPayload && this.sitesPayload.sortField
          ? this.sitesPayload.sortField
          : '',
        'sortDir': this.sitesPayload && this.sitesPayload.sortDir
          ? this.sitesPayload.sortDir
          : 0
      };
      if (!column.display) {
        if (column.value == this.sitesPayload.searchField) {
          searchObj = {
            'searchField': '',
            'search': '',
          };
          const columnName = column.value;
          programListStore.mutateSitesSearchObj({ ...this.siteSearchObj, [columnName]: '' });
          programListStore.mutateSitesPayload({ ...this.sitesPayload, searchField: searchObj.searchField, search: searchObj.search, isExactMatch: false });
          this.getSiteList();
        }
        if (column.value == this.sitesPayload.sortField) {
          sortObjc = {
            'sortField': '',
            'sortDir': 0,
          };
          programListStore.mutateSiteSortPayload({ ...this.sitesPayload, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir });
          programListStore.mutateSitesPayload({ ...this.sitesPayload, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir, isExactMatch: false });
          this.getSiteList();
        }
      }
    }

    presistResize(resize: { column: any; maxWidth: any; minWidth: any }) {
      programListStore.mutateSiteResizeData(resize);
    }

    /**
     * Navigate to site detail page
     *
     * @param siteData
     */
    viewSiteDetail(siteData: any) {
      APP_UTILITIES.setCookie('siteSessionId', JSON.stringify(siteData.siteId), 1);
      APP_UTILITIES.setCookie('siteName', JSON.stringify(siteData.siteName), 1);
      this.$router.push('/account/program/site');
    }

    destroyed() {
      tableStore.mutateSelectAll(false);
      this.clearSelection();
    }
}